import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { matchSorter } from 'match-sorter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { loadWorkspaces } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';
import routes from '../../../config/routes';
import WorkspaceSettingsDepositDialog from '../../../features/workspace-settings/WorkspaceSettingsDepositDialog';
import usePrevious from '../../../hooks/usePrevious';
import useURL from '../../../hooks/useURL';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import icon from '../../../static/icons';
import { useSidebarStore } from '../../../store/sidebar';
import { useUserStore } from '../../../store/user';
import { createQueryData, deleteQueryData, updateQueryData } from '../../../utils/queryClient';
import {
  Checker,
  checkIsAccountsVisible,
  checkWorkspaceHasChannelsAccess,
  checkIsClubManagersVisible,
  checkIsIdentitiesVisible,
  checkIsMarketVisible,
  checkIsOrdersVisible,
  checkIsSeatControlRulesVisible,
  checkIsDealsVisible,
} from '../../../utils/workspace';
import Button from '../../Button';
import Dialog from '../../Dialog';
import Dropdown from '../../Dropdown';
import SearchInput from '../../SearchInput';
import SidebarAvatar from '../SidebarAvatar';
import SidebarPlaceholder from '../SidebarPlaceholder';
import SidebarSeparator from '../SidebarSeparator';
import styles from './styles.module.scss';

const SECTIONS = {
  'market/identities': 'market/identities',
  accounts: 'accounts',
  channels: 'channels',
  'club-managers': 'club-managers',
  members: 'members',
  identities: 'identities',
  deals: 'deals',
  orders: 'orders',
  'seat-control-rules': 'seat-control-rules',
  settings: 'settings',
};

const sectionCheckers: Record<string, Checker> = {
  [SECTIONS['market/identities']]: checkIsMarketVisible,
  [SECTIONS.accounts]: checkIsAccountsVisible,
  [SECTIONS.channels]: checkWorkspaceHasChannelsAccess,
  [SECTIONS['club-managers']]: checkIsClubManagersVisible,
  [SECTIONS.identities]: checkIsIdentitiesVisible,
  [SECTIONS.deals]: checkIsDealsVisible,
  [SECTIONS.orders]: checkIsOrdersVisible,
  [SECTIONS['seat-control-rules']]: checkIsSeatControlRulesVisible,
};

const getSection = (pathname: string) => {
  if (pathname.includes(SECTIONS['market/identities'])) return SECTIONS['market/identities'];

  if (pathname.includes(SECTIONS.accounts)) return SECTIONS.accounts;
  if (pathname.includes(SECTIONS.channels)) return SECTIONS.channels;
  if (pathname.includes(SECTIONS['club-managers'])) return SECTIONS['club-managers'];
  if (pathname.includes(SECTIONS.members)) return SECTIONS.members;
  if (pathname.includes(SECTIONS.identities)) return SECTIONS.identities;
  if (pathname.includes(SECTIONS.deals)) return SECTIONS.deals;
  if (pathname.includes(SECTIONS.orders)) return SECTIONS.orders;
  if (pathname.includes(SECTIONS['seat-control-rules'])) return SECTIONS['seat-control-rules'];
  if (pathname.includes(SECTIONS.settings)) return SECTIONS.settings;

  return '';
};

const SidebarWorkspacesSelector = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();
  const { pathname } = useLocation();

  const [search, setSearch] = useState('');
  const { data: userData, workspace: defaultWorkspace, setWorkspace } = useUserStore();
  const { open } = useSidebarStore((state) => ({ open: state.open }));
  const [workspaceDepositId, setWorkspaceDepositId] = useState<WorkspaceSchema['id'] | null>(null);
  const [workspaceRemoved, setWorkspaceRemoved] = useState(false);

  const workspaceId = defaultWorkspace?.id;

  const queryKey = [queryKeys.workspaces];
  const queryFn = () => loadWorkspaces();

  const { data, isPending } = useQuery({ queryKey, queryFn });

  const workspaces = JSON.stringify(data);
  const prevWorkspaces = usePrevious(workspaces);

  useEffect(() => {
    if (data && workspaces !== prevWorkspaces) {
      const firstWorkspace = data[0];
      const currentWorkspace = data.find((item) => item.id === workspaceId);

      const newWorkspace = currentWorkspace || firstWorkspace;

      if (newWorkspace) setWorkspace(newWorkspace);
    }
  }, [data, workspaces, prevWorkspaces, workspaceId, setWorkspace]);

  const isWorkspacesSection = pathname.startsWith('/workspaces/');

  const changeWorkspace = (value: WorkspaceSchema) => {
    setWorkspace(value);

    if (isWorkspacesSection) {
      const section = getSection(pathname);

      const cheker = sectionCheckers[section];

      if (cheker ? !cheker(value.id) : false) {
        navigate(routes.home, { params: false });
      } else {
        navigate(`/workspaces/${value.id}/${section}`, { params: false });
      }
    }
  };

  useWebSocketMessage<WorkspaceSchema>({
    domain: 'Maintenance',
    onMessage: (message) => {
      if (message.action === 'Created') {
        if (message.payload.owner_id === userData?.id) {
          createQueryData(queryKey, message.payload);
        }
      }

      if (message.action === 'Edited' || message.action === 'OwnerChanged') {
        updateQueryData(queryKey, message.payload, (item) => item.id === message.payload.id);
      }

      if (message.action === 'Deleted') {
        deleteQueryData<WorkspaceSchema>(queryKey, (item) => item.id === message.payload.id);

        if (message.payload.id === workspaceId) {
          const filteredWorkspaces = data?.filter((item) => item.id !== workspaceId);

          if (filteredWorkspaces?.length) {
            changeWorkspace(filteredWorkspaces[0]);
          } else if (isWorkspacesSection) {
            navigate(routes.home, { params: false });
          }

          setWorkspaceRemoved(true);
        }
      }
    },
  });

  const workspace = data?.find((item) => item.id === workspaceId);

  if (isPending) {
    return (
      <SidebarPlaceholder isWorkspaces>
        <SidebarSeparator isWorkspaces />
      </SidebarPlaceholder>
    );
  }

  if (!data) return null;
  if (!workspace) return null;

  const getWorkspaces = () => {
    const selectedWorkpspace = data.find((item) => item.id === workspaceId);
    let sortedData = data;

    sortedData = sortedData.filter((item) => item.id !== workspaceId);

    if (search) sortedData = matchSorter(sortedData, search, { keys: ['name'] });

    return selectedWorkpspace ? [selectedWorkpspace, ...sortedData] : sortedData;
  };

  return (
    <>
      {workspaceDepositId && (
        <WorkspaceSettingsDepositDialog
          workspaceId={workspaceDepositId}
          onClose={() => setWorkspaceDepositId(null)}
        />
      )}
      {workspaceRemoved && (
        <Dialog>
          {({ close }) => (
            <>
              <Dialog.Top>
                <Dialog.Title>{t('common.attention')}</Dialog.Title>
              </Dialog.Top>
              <span className={styles.description}>
                {t('sentences.workspace_has_been_removed')}
              </span>
              <Dialog.Footer>
                <Button onClick={() => close()}>{t('common.ok')}</Button>
              </Dialog.Footer>
            </>
          )}
        </Dialog>
      )}
      <Dropdown
        placement="bottom"
        title={t('common.workspaces')}
        className={styles.dropdown}
        arrowOffset={12}
        options={({ closeDropdown }) => (
          <>
            {getWorkspaces().map((item) => {
              const active = workspaceId === item.id;

              const handleClick = () => {
                changeWorkspace(item);
                closeDropdown();
              };

              return (
                <div key={item.id} className={clsx(styles.dropdownOption, active && styles.active)}>
                  <button
                    type="button"
                    onClick={handleClick}
                    className={styles.dropdownOptionContent}
                  >
                    <SidebarAvatar name={item.name} />
                    <div>
                      <span>{item.name}</span>
                      {item.owner_id === userData?.id && (
                        <div className={styles.dropdownLabelOwner}>{icon('crown', 12)}</div>
                      )}
                    </div>
                  </button>
                  <Dropdown
                    closeOnScroll
                    title={item.name}
                    options={[
                      {
                        label: t('common.deposit'),
                        icon: icon('deposit', 20),
                        onClick: () => setWorkspaceDepositId(item.id),
                      },
                      {
                        label: t('common.settings'),
                        icon: icon('settings', 20),
                        onClick: () =>
                          navigate(routes.workspaceSettings({ workspaceId: item.id }), {
                            params: false,
                          }),
                      },
                    ]}
                  >
                    {() => (
                      <div className={styles.dropdownOptionDropdown}>
                        <Button size="small" variant="ghost" icon={icon('moreVertical', 16)} />
                      </div>
                    )}
                  </Dropdown>
                </div>
              );
            })}
          </>
        )}
        {...(data.length > 5 && {
          addon: <SearchInput value={search} onChange={setSearch} className={styles.search} />,
        })}
      >
        {({ open: dropdownOpen, closeDropdown }) => {
          if (dropdownOpen && !open) closeDropdown();

          return (
            <button
              type="button"
              className={clsx(styles.workspaces, {
                [styles.open]: open,
                [styles.dropdownOpen]: dropdownOpen,
              })}
            >
              <SidebarAvatar name={workspace.name} />
              <span>
                {workspace.name}
                {workspace.owner_id === userData?.id && icon('crown', 12)}
              </span>
              {icon('chevronDown', 16)}
            </button>
          );
        }}
      </Dropdown>
    </>
  );
};

export default SidebarWorkspacesSelector;
