import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalClubs } from '../../../api';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import Button from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Select from '../../../components/Select';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { copyClubCode, copyClubName } from '../../../utils/clipboard';
import notify from '../../../utils/notify';
import { GlobalAccountFields as Fields } from '../../account/types';
import styles from './styles.module.scss';

interface Props {
  data: GlobalAccountSchema;
  disabled: boolean;
  onSubmit: () => Promise<void>;
}

const GlobalAccountDetailClubs = (props: Props) => {
  const { data, disabled, onSubmit } = props;

  const { room, clubs, stopped_clubs } = data;

  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<Fields>();

  const checkStopped = (value: GlobalAccountSchema['clubs'][number]) =>
    stopped_clubs.map((el) => el.id).includes(value.id);

  const clubIds = watch('club_ids');
  const stoppedClubIds = watch('stopped_club_ids');

  return (
    <div className={styles.container}>
      {clubs.map((item) => (
        <div key={item.id} className={styles.club}>
          <div>
            <button
              type="button"
              onClick={() => copyClubName(item.name)}
              className={clsx(styles.button, styles.title)}
            >
              {item.name}
            </button>
            <button
              type="button"
              onClick={() => copyClubCode(item.code)}
              className={clsx(styles.button, styles.subTitle)}
            >
              {item.code}
            </button>
          </div>
          {checkStopped(item) && icon('pauseCircle', 16)}
          <div className={styles.controls}>
            <Tooltip
              label={
                checkStopped(item)
                  ? t('sentences.remove_club_from_stop_list')
                  : t('sentences.add_club_to_stop_list')
              }
            >
              <Button
                size="extra-small"
                variant="light"
                icon={checkStopped(item) ? icon('playCircle', 16) : icon('pauseCircle', 16)}
                onClick={() => {
                  if (checkStopped(item)) {
                    setValue(
                      'stopped_club_ids',
                      stoppedClubIds.filter((el) => el !== item.id)
                    );
                  } else {
                    setValue('stopped_club_ids', [...stoppedClubIds, item.id]);
                  }

                  onSubmit();

                  notify('success', {
                    title: checkStopped(item)
                      ? t('sentences.club_has_been_removed_from_stop_list')
                      : t('sentences.club_has_been_added_to_stop_list'),
                  });
                }}
              />
            </Tooltip>
            <Tooltip label={t('common.delete')}>
              <Confirm
                onConfirm={() => {
                  setValue(
                    'club_ids',
                    clubIds.filter((el) => el.value !== item.id)
                  );

                  onSubmit();
                }}
                title={t('sentences.delete_this_club')}
                confirmText={t('common.delete')}
              >
                {({ confirming }) => (
                  <Button
                    danger
                    focused={confirming}
                    size="extra-small"
                    variant="light"
                    icon={icon('trash', 16)}
                  />
                )}
              </Confirm>
            </Tooltip>
          </div>
        </div>
      ))}
      <Select.Async
        onLoad={async (args) => {
          const response = await loadGlobalClubs({
            ...args,
            poker_room_in: `${room}`,
          });

          const options = response.items.map((item) => ({
            value: item.id,
            label: item.name,
            caption: item.code,
          }));

          return options;
        }}
      >
        {({ options, ...selectAsyncProps }) => (
          <Select.Quick
            name="club_ids"
            disabled={disabled}
            options={options.filter((item) => !clubIds.map((el) => el.value).includes(item.value))}
            onChange={(value) => {
              setValue('club_ids', value ? [...clubIds, value] : []);

              onSubmit();
            }}
            {...selectAsyncProps}
          />
        )}
      </Select.Async>
    </div>
  );
};

export default GlobalAccountDetailClubs;
