import clsx from 'clsx';
import icon from '../../../static/icons';
import { SelectProvider } from '../SelectContext';
import SelectField from '../SelectField';
import SelectMultiQuick from '../SelectMultiQuick';
import SelectOptions from '../SelectOptions';
import { Props as SelectProps, Option, Options, OptionValue } from '../types';
import styles from './styles.module.scss';

interface Props<T extends OptionValue = string> extends SelectProps<T> {
  value: Options<T>;
  onChange: (options: Options<T>) => void;
}

const SelectMulti = <T extends OptionValue = string>(props: Props<T>) => {
  const {
    value,
    options = [],
    addon,
    renderValueAsTag,
    className,
    onChange,
    disabled,
    quick,
    ...rest
  } = props;

  const selected = value.map((item) => item.value);

  const handleChange = (option: NonNullable<Option<T>>) => {
    const isValueSelected = selected.includes(option.value);

    const newValue = isValueSelected
      ? value.filter((item) => item.value !== option.value)
      : [...value, option];

    onChange(newValue);
  };

  const onClear = () => onChange([]);

  const renderValue = () => {
    if (value.length > 0) {
      if (renderValueAsTag) {
        return value.map((item) => {
          const handleClick = () => onChange(value.filter((option) => option.value !== item.value));

          return (
            <div key={item.value} className={clsx(styles.tag, quick && styles.quick)}>
              {item.label}
              {!quick && (
                <button type="button" onClick={handleClick} disabled={disabled}>
                  {icon('cross', 12)}
                </button>
              )}
            </div>
          );
        });
      }

      return `${value[0].label}${value.length > 1 ? ` (+${value.length - 1})` : ''}`;
    }

    return null;
  };

  return (
    <SelectProvider
      multi
      quick={quick}
      options={options}
      selected={selected}
      renderValueAsTag={renderValueAsTag}
      disabled={disabled}
      {...rest}
    >
      <div {...(quick && { 'data-quick': true })} className={clsx(styles.container, className)}>
        <SelectField addon={addon} onClear={onClear}>
          {renderValue()}
        </SelectField>
      </div>
      <SelectOptions onChange={handleChange} />
    </SelectProvider>
  );
};

SelectMulti.Quick = SelectMultiQuick;

export default SelectMulti;
